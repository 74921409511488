<template>
  <div class="vs-row" style="justify-content: space-between; display: flex; width: 100%;">
    <div class="vs-col vs-pagination--mb vs-xs-12 vs-sm-12 vs-lg-6" style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;"></div>
    <div class="vs-col vs-pagination--mb vs-xs-12 vs-sm-12 vs-lg-6" style="justify-content: flex-end; display: flex; align-items: center; margin-left: 0%; width: 100%;">
      <div class="con-vs-pagination vs-pagination-primary">
        <nav class="vs-pagination--nav">
          <button class="vs-pagination--buttons btn-prev-pagination vs-pagination--button-prev disabled" disabled="disabled">
            <i class="vs-icon notranslate icon-scale material-icons null">chevron_left</i>
          </button>
          <ul v-if="pager.pages && pager.pages.length" class="vs-pagination--ul">
            <li v-for="(page, index) in pager.pages" :key="index" :class="{'is-current':pager.currentPage === page}" class="item-pagination vs-pagination--li">
              <router-link :to="{ query: { page: page }}">
                <span> {{page}} </span>
                <div class="effect"></div>
              </router-link>
            </li>
            <!-- <li class="item-pagination vs-pagination--li">
              <span> ... </span>
              <div class="effect"></div>
            </li> -->
          </ul>
          <button class="vs-pagination--buttons btn-next-pagination vs-pagination--button-next">
            <i class="vs-icon notranslate icon-scale material-icons null">chevron_right</i>
          </button><!---->
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['pager', 'pageOfItems', 'IdOfficine'],
  data () {
    return {

    }
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler (page) {
        page = parseInt(page) || 1

        if (page !== this.pager.currentPage) {
          const payload = {
            officineId: this.IdOfficine,
            Page: page
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('commande_fourn/getBonReceptionByOfficine', payload)
            .then(() => {
              this.$vs.loading.close()
            })
            .catch(err => {
              console.log(err)
              this.$vs.loading.close()
            })
        }
      }
    }
  }
}
</script>
